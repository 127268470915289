<template>
    <v-container>
        <v-row>
            <v-col cols="12" xl="3" lg="4" md="5">
                <v-sheet rounded="lg">
                    <v-list color="transparent">
                        <v-list-item v-for="documentation in documentations" :key="documentation.id" link @click="selectPage(documentation)">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span class="d-flex justify-space-between align-center">
                                        <b :class="currentPage && currentPage.id == documentation.id ? 'lighter--text' : ''">{{ documentation.title }}</b>
                                        <span v-if="profile.role == 'SuperAdmin'">
                                            <v-btn color="secondary" icon @click.prevent.stop="openEditModal(documentation)">
                                                <v-icon>mdi-pencil-outline</v-icon>
                                            </v-btn>
                                            <v-btn color="error" icon @click.prevent.stop="deletePage(documentation)">
                                                <v-icon>mdi-delete-outline</v-icon>
                                            </v-btn>
                                        </span>
                                    </span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider class="my-2" v-if="profile.role == 'SuperAdmin'"></v-divider>

                        <v-list-item link color="grey lighten-4" v-if="profile.role == 'SuperAdmin'">
                            <v-list-item-content>
                                <v-list-item-title @click="openNewModal">
                                    <v-icon>mdi-plus</v-icon>
                                    Ajouter une page
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-sheet>
            </v-col>

            <v-col cols="12" xl="9" lg="8" md="7">
                <v-sheet min-height="70vh" rounded="lg" class="pa-5">
                    <span v-if="currentPage && currentPage.title && currentPage.innerHtml">
                        <div v-html="currentPage.innerHtml"></div>
                    </span>
                </v-sheet>
            </v-col>
        </v-row>

        <v-dialog v-model="modalIsVisible" max-width="1000px" persistent>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" md="7" lg="8" xl="9">
                            <v-text-field label="Titre" v-model="modalData.title" />
                        </v-col>
                        <v-col cols="12" sm="6" md="5" lg="4" xl="3">
                            <v-text-field type="number" label="Priorité" v-model="modalData.priority" />
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="12">
                            <v-textarea label="Text" rows="10" v-model="modalData.markdown"></v-textarea>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="modalIsVisible = false"> Annuler </v-btn>
                    <v-btn color="blue darken-1" text @click="saveDocumentation"> sauvegarder </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<style scoped>
    * >>> img{
        padding: 2%;
        padding-left: 5%;
        padding-right: 5%;
        width: 100%;
    }
    * >>> aside{
        background: var(--v-scrollBg-base);
        padding: .3rem;
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
    * >>> h2{
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
    * >>> h1{
        margin-top: 2rem;
        margin-bottom: 3rem;
    }
</style>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { marked } from 'marked'

import Documentation from '@/classes/Documentation'

export default {
    name: "documentation",
    props: ["profile"],
    data() {
        return {
            documentations: [],
            currentPage: null,
            modalIsVisible: false,
            modalData: {
                id: null,
                title: '',
                priority: 0,
                markdown: '',
            },
            unsub: [],
        }
    },
    created() {
        this.unsub.push(Documentation.listenAll(documentations => {
            let tmp_documentations = []

            for (let documentation of documentations) {
                documentation.innerHtml = marked.parse(documentation.markdown, { mangle: false, headerIds: false })
                tmp_documentations.push(documentation)
            }

            tmp_documentations.sort(function (a, b) {
                return b.priority - a.priority
            });

            this.documentations = tmp_documentations
            this.currentPage = this.documentations[0]
        }))
    },
    methods: {
        selectPage(documentation) {
            this.currentPage = documentation
        },
        openNewModal() {
            this.modalData.id = null
            this.modalData.title = ''
            this.modalData.priority = 0
            this.modalData.markdown = ''
            this.modalIsVisible = true
        },
        openEditModal(documentation) {
            this.modalData.id = documentation.id
            this.modalData.title = documentation.title
            this.modalData.priority = documentation.priority
            this.modalData.markdown = documentation.markdown
            this.modalIsVisible = true
        },
        saveDocumentation() {
            let documentation
            if (this.modalData.id == null) {
                documentation = new Documentation(null, this.modalData.title, this.modalData.priority, this.modalData.markdown)
            } else {
                documentation = new Documentation(this.modalData.id, this.modalData.title, this.modalData.priority, this.modalData.markdown)
            }
            documentation.save()

            this.modalIsVisible = false
        },
        deletePage(documentation) {
            Swal.fire({
                title: "Etes-vous sûr ?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Annuler",
            })
            .then(result => {
                if(result.isConfirmed){
                    documentation.delete()
                }
            })
        }
    },
    destroyed() {
        this.unsub.forEach((unsub) => unsub())
    },
}
</script>