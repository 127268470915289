import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "documentations"

class Documentation{
    constructor(id, title, priority, markdown){
        this.id = id
        this.title = title
        this.priority = priority
        this.markdown = markdown
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let documentations = []

        response.forEach(document => {
            let data = document.data()
            let tmp_documentation = new Documentation(document.id, data.title, data.priority, data.markdown)
            documentations.push(tmp_documentation)
        })

        return documentations
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let documentation = new Documentation(response.id, data.title, data.priority, data.markdown)

        return documentation
    } 

    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let documentations = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_documentation = new Documentation(document.id, data.title, data.priority, data.markdown)
                documentations.push(tmp_documentation)
            })
            
            if(callback != null){
                callback(documentations)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let documentation = new Documentation(snapshot.id, data.title, data.priority, data.markdown)
            
            if(callback != null){
                callback(documentation)
            }
        })
        return unsub
    }

    static async updateById(id, newData){
        let documentRef = doc(db, collectionName, id)
        await updateDoc(documentRef, newData)
    }

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                title: this.title,
                priority: this.priority,
                markdown: this.markdown,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                title: this.title,
                priority: this.priority,
                markdown: this.markdown,
            })
        }
    }

    async delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Documentation